import React, { forwardRef } from 'react'

const DangerousHtml = forwardRef(function DangerousHtml(
    { tag: Tag, children: __html, dangerouslySetInnerHTML, ...props },
    ref,
) {
    return (
        <Tag
            {...props}
            dangerouslySetInnerHTML={{
                __html,
            }}
            ref={ref}
        />
    )
})

export default DangerousHtml
