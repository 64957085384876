import React from 'react'
import { Element } from 'react-scroll'
import styled from 'styled-components'
import { MqDesktop } from 'streamr-ui/css/consts'
import Hero from './Hero'
import Quickstart from './Quickstart'
import Using from './Using'
import Layout from '~shared/Layout'
import Container from '~shared/Container'
import MarketplaceHeroMockup from '~shared/Mockup/MarketplaceHeroMockup'
import ScrollEncourager from '~shared/ScrollEncourager'
import MockupContainer from '~shared/MockupContainer'
import Topic from '~shared/Topic'
import Display from '~shared/Display'
import Spacer from '~shared/Spacer'

const QuickstartTitle = styled(Topic.Title)``

const MockupWrapper = styled.div`
    border-radius: 0.25rem;
    margin-top: 15rem;
    overflow: hidden;

    @media ${MqDesktop} {
        margin-top: 9.25rem;
    }
`

const QuickstartTopic = styled(Topic)`
    ${Container} {
        padding: 0 1.5rem;
    }

    ${Topic.Title} {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        @media ${MqDesktop} {
            text-align: left;
            margin-left: 0;
        }
    }
`

const UnstyledLearnMarketplace = (props) => (
    <Layout {...props}>
        <Hero />
        <Display mobile="none" desktop="block">
            <Spacer head="168px,,">
                <ScrollEncourager offset={120} onBright center />
            </Spacer>
        </Display>
        <Display as={MockupContainer} mobile="none" tablet="block">
            <MockupWrapper>
                <MarketplaceHeroMockup />
            </MockupWrapper>
        </Display>
        <Element name={ScrollEncourager.TargetID} />
        <QuickstartTopic head="5rem,11.5rem">
            <Container>
                <QuickstartTitle hash="quickstart" tail="3rem,7rem,4rem" $appear>
                    Marketplace Quickstart
                </QuickstartTitle>
                <Topic.Body>
                    <Quickstart />
                </Topic.Body>
            </Container>
        </QuickstartTopic>
        <Spacer tail="4rem,8rem,14rem" />
        <Using />
    </Layout>
)

const LearnMarketplace = styled(UnstyledLearnMarketplace)`
    color: #0c009a;
`

export default LearnMarketplace
