import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from '~shared/FluidImage'

export default function MarketplaceMockup(props) {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "shared/Mockup/assets/marketplace_mockup.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 985, placeholder: NONE)
                }
            }
        }
    `).file.childImageSharp.gatsbyImageData

    return (
        <Img
            {...props}
            data={{
                ...data,
                sizes: '(max-width: 767px) 100vw, (min-width: 1200px) 985px, 640px',
            }}
        />
    )
}
