import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Medium, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Mew from './assets/via-myetherwallet.png'
import Mew2x from './assets/via-myetherwallet@2x.png'
import Metamask from './assets/via-metamask.png'
import Metamask2x from './assets/via-metamask@2x.png'
import Uniswap from './assets/via-uniswap.png'
import Uniswap2x from './assets/via-uniswap@2x.png'
import PrestyledLink from '~shared/Link'
import Display from '~shared/Display'
import useTranslation from '~utils/useTranslation'
import urls from '~utils/urls'
import DangerousHtml from '~shared/DangerousHtml'
import { NEW_TOKEN_ADDRESS } from '~utils/ethereum'

const Title = styled.h4`
    margin: 0;
    font-weight: ${Medium};
    line-height: 1.5rem;
    font-size: 0.875rem;

    @media ${MqTablet} {
        line-height: 2rem;
        font-size: 1.125rem;
    }
`

const Description = styled.p`
    margin: 0;
    line-height: 1.5rem;
    font-size: 0.875rem;

    @media ${MqTablet} {
        font-size: 1rem;
        line-height: 1.75rem;
    }
`

const Icon = styled.div``

const Link = styled(PrestyledLink)`
    background-color: #fafafa;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0.75rem 1rem 1.5rem;
    position: relative;
    text-decoration: none;

    ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        transition: opacity 300ms ease-in-out;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
    }

    :hover {
        ::after {
            opacity: 1;
        }
    }

    ${Icon} {
        min-width: 52px;
        position: relative;

        img {
            height: 100%;
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    > * + * {
        margin-left: 1.5rem;
    }

    @media ${MqTablet} {
        padding: 2rem 2.5rem;

        ${Icon} {
            min-width: 160px;
        }

        > * + * {
            margin-left: 2rem;
        }
    }

    @media ${MqDesktop} {
        padding: 4.5rem 2.5rem 2rem;
        flex-direction: column;
        flex: 1;

        ${Icon} {
            min-width: none;
            margin-bottom: 3.75rem;
        }

        > * + * {
            margin-left: 0;
            margin-top: 0.5rem;
        }
    }
`

const StepLink = forwardRef(function StepLink(props, ref) {
    return <Link {...props} newTab noDecoration noHover ref={ref} $appear />
})

function QuickstartSteps(props) {
    const { t } = useTranslation()

    return (
        <div {...props}>
            <StepLink href={urls.tokens.metamask}>
                <Icon
                    css={css`
                        min-height: 36px;

                        @media ${MqTablet} {
                            min-height: 64px;
                        }

                        @media ${MqDesktop} {
                            min-height: 100px;
                        }
                    `}
                >
                    <img src={Metamask} srcSet={`${Metamask2x} 2x`} alt="Metamask" />
                </Icon>
                <div>
                    <Title>{t('learn.marketplace.quickstart.web3.title')}</Title>
                    <Description>
                        <DangerousHtml tag="span">
                            {t('learn.marketplace.quickstart.web3.description')}
                        </DangerousHtml>
                    </Description>
                </div>
            </StepLink>
            <StepLink href={urls.tokens.mew}>
                <Icon
                    css={css`
                        min-height: 48px;

                        @media ${MqTablet} {
                            min-height: 72px;
                        }

                        @media ${MqDesktop} {
                            min-height: 100px;
                        }
                    `}
                >
                    <img src={Mew} srcSet={`${Mew2x} 2x`} alt="MyEtherWallet" />
                </Icon>
                <div>
                    <Title>{t('learn.marketplace.quickstart.wallet.title')}</Title>
                    <Description>
                        <DangerousHtml tag="span">
                            {t('learn.marketplace.quickstart.wallet.description')}
                        </DangerousHtml>
                    </Description>
                </div>
            </StepLink>
            <StepLink href={`${urls.tokens.uniswap}?outputCurrency=${NEW_TOKEN_ADDRESS}`}>
                <Icon
                    css={css`
                        min-height: 44px;

                        @media ${MqTablet} {
                            min-height: 64px;
                        }

                        @media ${MqDesktop} {
                            min-height: 100px;
                        }
                    `}
                >
                    <img src={Uniswap} srcSet={`${Uniswap2x} 2x`} alt="Uniswap" />
                </Icon>
                <div>
                    <Title>{t('learn.marketplace.quickstart.protocol.title')}</Title>
                    <Description>
                        <DangerousHtml tag={Display} as="span" desktop="none">
                            {t('learn.marketplace.quickstart.protocol.description')}
                        </DangerousHtml>
                        <DangerousHtml tag={Display} as="span" mobile="none" desktop="inline">
                            {t('learn.marketplace.quickstart.protocol.descriptionDesktop')}
                        </DangerousHtml>
                    </Description>
                </div>
            </StepLink>
        </div>
    )
}

export default styled(QuickstartSteps)`
    display: flex;
    flex-direction: column;

    ${Link} {
    }

    ${Link} + ${Link} {
        margin-top: 1rem;
    }

    @media ${MqTablet} {
        ${Link} + ${Link} {
            margin-top: 2rem;
        }
    }

    @media ${MqDesktop} {
        flex-direction: row;

        ${Link} + ${Link} {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
`
