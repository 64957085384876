import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import MARKETPLACE from '~shared/Icon/assets/marketplace.png'
import PageIntro from '~shared/PageIntro'
import DangerousHtml from '~shared/DangerousHtml'
import Container from '~shared/Container'
import MockupContainer from '~shared/MockupContainer'
import urls from '~utils/urls'
import useTranslation from '~utils/useTranslation'
import { Icon as PrestyledIcon } from '~shared/Hero'
import ButtonWrapper from '~shared/ButtonWrapper'
import { PrimaryButton, OutlinedButton, ButtonSize, ButtonPaint, Link } from 'streamr-ui'

const Icon = styled(PrestyledIcon)`
    width: 160px;

    @media ${MqTablet} {
        width: 220px;
    }
`

const UnstyledHero = (props) => {
    const { t } = useTranslation()

    return (
        <div {...props}>
            <Container>
                <PageIntro>
                    <PageIntro.Icon $appear>
                        <Icon src={MARKETPLACE} />
                    </PageIntro.Icon>
                    <PageIntro.Content>
                        <DangerousHtml tag="h1" $appear>
                            {t('learn.marketplace.hero.title')}
                        </DangerousHtml>
                        <DangerousHtml tag="p" $appear>
                            {t('learn.marketplace.hero.description')}
                        </DangerousHtml>
                        <PageIntro.Buttons>
                            <ButtonWrapper>
                                <PrimaryButton
                                    tag={Link}
                                    href={urls.apps.hub}
                                    size={ButtonSize.Moose}
                                >
                                    {t('learn.marketplace.hero.browseMarketplace')}
                                </PrimaryButton>
                            </ButtonWrapper>
                            <ButtonWrapper>
                                <OutlinedButton
                                    tag={Link}
                                    href={urls.docs.index}
                                    size={ButtonSize.Moose}
                                    paint={ButtonPaint.PrimaryOutlined}
                                >
                                    {t('learn.marketplace.hero.marketplaceDocs')}
                                </OutlinedButton>
                            </ButtonWrapper>
                        </PageIntro.Buttons>
                    </PageIntro.Content>
                </PageIntro>
            </Container>
            <MockupContainer.Gradient />
        </div>
    )
}

const Hero = styled(UnstyledHero)`
    position: relative;
    padding-bottom: 5rem;

    ${PageIntro.Content} {
        h1 {
            margin-bottom: 2.5rem;
        }
    }

    ${PageIntro.Buttons} {
        margin-top: 3.5rem;
    }

    @media ${MqTablet} {
        padding-bottom: 0;

        ${PageIntro.Content} {
            p {
                width: 460px;
            }
        }

        ${MockupContainer.Gradient} {
            display: none;
        }
    }

    @media ${MqDesktop} {
        ${PageIntro.Content} {
            p {
                width: 43.5rem;
            }
        }
    }
`

export default Hero
