import React from 'react'
import styled from 'styled-components'
import QuickstartSteps from './QuickstartSteps'
import Spacer from '~shared/Spacer'
import ButtonWrapper from '~shared/ButtonWrapper'
import urls from '~utils/urls'
import useTranslation from '~utils/useTranslation'
import { PrimaryButton, ButtonSize } from 'streamr-ui'

const ButtonsSpacer = styled(Spacer)`
    text-align: center;
`

const Quickstart = (props) => {
    const { t } = useTranslation()

    return (
        <div {...props}>
            <QuickstartSteps />
            <ButtonsSpacer head="48px,80px,104px">
                <ButtonWrapper>
                    <PrimaryButton tag="a" href={urls.apps.hub} size={ButtonSize.Moose}>
                        {t('learn.marketplace.quickstart.connectWallet')}
                    </PrimaryButton>
                </ButtonWrapper>
            </ButtonsSpacer>
        </div>
    )
}

export default styled(Quickstart)``
