import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const Gradient = styled.div`
    background: linear-gradient(rgba(255, 255, 255, 0), #f5f5f5);
    bottom: 0;
    height: 185%;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: -1;
`

const Inner = styled.div`
    /* We mimic Container here with a little bit of extra space on top of tablet breakpoint. */
    margin: 0 auto;
    padding: 0;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @media ${MqTablet} {
        max-width: 40rem;
    }

    @media ${MqDesktop} {
        max-width: 69rem;
    }
`

const UnstyledMockupContainer = ({ children, ...props }) => (
    <div {...props}>
        <Inner>{children}</Inner>
        <Gradient />
    </div>
)

const MockupContainer = styled(UnstyledMockupContainer)`
    overflow: visible;
    position: relative;
`

export default Object.assign(MockupContainer, {
    Gradient,
})
