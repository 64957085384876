import styled from 'styled-components'
import { Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const Buttons = styled.div`
    margin: 2.5rem 0 0;
    display: inline-flex;
    flex-direction: column;

    > * + * {
        margin-top: 2rem;
    }

    @media ${MqTablet} {
        margin-top: 3.5rem;
        white-space: nowrap;
        display: block;

        > * + * {
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    @media ${MqDesktop} {
        margin: 4rem auto 0;
    }
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`

const Content = styled.div`
    @media ${MqTablet} {
        padding-left: 4rem;
    }

    @media ${MqDesktop} {
        padding-left: 0;
    }
`

const PageIntro = styled.div`
    text-align: left;
    margin-top: 2.5rem;

    h1 {
        font-size: 2rem;
        font-weight: ${Regular};
        line-height: 2.5rem;
        margin: 0;
        margin-bottom: 1em;
        text-align: left;
    }

    p {
        font-size: 1rem;
        font-weight: ${Regular};
        letter-spacing: 0;
        line-height: 1.75rem;
        margin: 0;
    }

    ${Icon} + ${Content} {
        margin-top: 4rem;
    }

    @media ${MqTablet} {
        margin-top: 4rem;

        h1 {
            font-size: 2.5rem;
            line-height: 3.25rem;
        }

        p {
            font-size: 1.125rem;
            line-height: 1.875rem;
            width: 75%;
        }

        ${Icon} + ${Content} {
            margin-top: 4.5rem;
        }
    }

    @media ${MqDesktop} {
        margin: 11.5rem auto 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        h1 {
            line-height: 3.5rem;
            font-size: 3rem;
        }

        p {
            margin: 0 auto;
            width: 43.5rem;
        }

        ${Icon} + ${Content} {
            margin-top: 0;
            margin-left: 13rem;
        }
    }
`

Object.assign(PageIntro, {
    Buttons,
    Content,
    Icon,
})

export default PageIntro
