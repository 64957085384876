import React from 'react'
import { withAssetPrefix } from 'gatsby'
import Marketplace from '../../components/Marketplace'
import pkg from '../../../package'
import page from '~utils/page'
import { APPS } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(Marketplace, {
    highlight: APPS,
    headComponent: (
        <Head
            // eslint-disable-next-line max-len
            description="Buy and sell real-time data on the Streamr Marketplace, which features a range of data products, from cryptocurrency feeds to pollution data."
            mediaTitle="Streamr Marketplace"
            posterUrl={withAssetPrefix(`/resources/social/marketplace.png?v=${pkg.version}`)}
            mediaDescription="Buy and sell real-time data"
        />
    ),
})
