export default {
    top: {
        url: 'https://www.youtube.com/embed/nbWIPVE8zFE?autoplay=1&loop=1&mute=1',
    },
    buying: {
        url: 'https://player.vimeo.com/video/341710575?autoplay=1&loop=1&autopause=0&muted=1',
    },
    selling: {
        url: 'https://player.vimeo.com/video/341707750?autoplay=1&loop=1&autopause=0&muted=1',
    },
}
