import React from 'react'
import styled from 'styled-components'
import { Medium } from 'streamr-ui/css/consts'
import UnstyledPlayButton from '~shared/PlayButton'

const Caption = styled.div`
    bottom: 1.25rem;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    transition: 250ms ease-in-out opacity;
    letter-spacing: 1.86px;
    z-index: 0;
`

const PlayButton = styled(UnstyledPlayButton)`
    z-index: 1;
`

const UnstyledVideoThumbnail = ({ children, caption, onClick, ...props }) => (
    <div {...props}>
        {children}
        <PlayButton onClick={onClick} monolith />
        {!!caption && <Caption>{caption}</Caption>}
    </div>
)

const VideoThumbnail = styled(UnstyledVideoThumbnail)`
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: ${Medium};
    outline: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    ${PlayButton} {
        :hover + ${Caption},
        :focus + ${Caption} {
            opacity: 1;
        }
    }
}

`

export default VideoThumbnail
