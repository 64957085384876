import React from 'react'
import styled from 'styled-components'
import videos from './videos'
import useTranslation from '~utils/useTranslation'
import VideoModal from '~shared/VideoModal'

const UnstyledVideoPlayer = ({ id, ...props }) => {
    const { t } = useTranslation()

    return (
        <VideoModal {...props}>
            {!!videos[id] && <iframe title={t('top.hero.videoTitle')} src={videos[id].url} />}
        </VideoModal>
    )
}

const VideoPlayer = styled(UnstyledVideoPlayer)`
    iframe {
        border: none;
        display: block;
        height: 100%;
        width: 100%;
    }
`

export default VideoPlayer
