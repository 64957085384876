import React, { useState } from 'react'
import styled from 'styled-components'
import { Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import VideoThumbnail from '~shared/VideoThumbnail'
import VideoPlayer from '~shared/VideoPlayer'
import DangerousHtml from '~shared/DangerousHtml'
import useTranslation from '~utils/useTranslation'
import ProductMockup from '~shared/Mockup/ProductMockup'
import MarketplaceMockup from '~shared/Mockup/MarketplaceMockup'
import Display from '~shared/Display'
import Topic from '~shared/Topic'
import Container from '~shared/Container'
import Spacer from '~shared/Spacer'

const Image = styled.div`
    position: relative;
`

const Description = styled.div`
    @media ${MqTablet} {
        max-width: 460px;
    }

    @media ${MqDesktop} {
        max-width: none;
    }
`

const Panel = styled.div`
    display: grid;
    grid-row-gap: 2rem;

    @media ${MqTablet} {
        grid-row-gap: 8.5rem;
    }

    @media ${MqDesktop} {
        grid-row-gap: 2rem;
        grid-column-gap: 10rem;
        grid-template-columns: 1fr 1fr;
    }
`

const Panels = styled.div`
    display: grid;
    grid-row-gap: 8.25rem;

    h3 {
        margin-top: 0;
        font-size: 1.75rem;
        font-weight: ${Regular};
        letter-spacing: 0;
        line-height: 2.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin: 0;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1.75rem;
    }

    @media ${MqTablet} {
        margin-top: 8.5625rem;
        grid-row-gap: 10rem;

        h3 {
            margin-bottom: 2.5rem;
            font-size: 1.5rem;
            line-height: 2rem;
        }

        p {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
    }

    @media ${MqDesktop} {
        grid-row-gap: 15.5rem;

        ${Panel}:nth-child(odd) {
            ${Image} {
                grid-row: 1;
                grid-column: 1;
            }

            ${VideoThumbnail} {
                position: absolute;
                right: 0;
                width: 985px;
            }

            ${Description} {
                grid-row: 1;
                grid-column: 2;
            }
        }

        ${Panel}:nth-child(even) {
            margin-top: 15.5rem;

            ${Image} {
                grid-row: 1;
                grid-column: 2;
            }

            ${VideoThumbnail} {
                position: absolute;
                left: 0;
                width: 985px;
            }

            ${Description} {
                grid-row: 1;
                grid-column: 1;
            }
        }
    }
`

const EndRow = styled.div`
    display: none;

    @media ${MqDesktop} {
        display: block;
        margin-top: 4rem;
    }
`

const UnstyledUsing = (props) => {
    const { t } = useTranslation()

    const [activeVideo, setActiveVideo] = useState(null)

    return (
        <div {...props}>
            {!!activeVideo && (
                <VideoPlayer onCloseClick={() => setActiveVideo(null)} id={activeVideo} />
            )}
            <Topic as={Spacer} head="5rem,7em,13.5rem" tail="8.5rem,14rem,22rem">
                <Display
                    as={Topic.Title}
                    mobile="none"
                    tablet="block"
                    hash="using-marketplace"
                    $appear
                >
                    Using the Marketplace
                </Display>
                <Topic.Body>
                    <Container>
                        <Panels>
                            <Panel>
                                <Description>
                                    <div $appear>
                                        <Display as="h3" tablet="none">
                                            {t('learn.marketplace.usage.selling.title.sm')}
                                        </Display>
                                        <Display as="h3" mobile="none" tablet="block">
                                            {t('learn.marketplace.usage.selling.title.xl')}
                                        </Display>
                                    </div>
                                    <div $appear>
                                        <Display
                                            as={DangerousHtml}
                                            tag="p"
                                            mobile="none"
                                            tablet="block"
                                        >
                                            {t(
                                                'learn.marketplace.usage.selling.description.desktop',
                                            )}
                                        </Display>
                                        <Display
                                            as={DangerousHtml}
                                            tag="p"
                                            mobile="block"
                                            tablet="none"
                                        >
                                            {t(
                                                'learn.marketplace.usage.selling.description.mobile',
                                            )}
                                        </Display>
                                    </div>
                                </Description>
                                <Image>
                                    <VideoThumbnail onClick={() => setActiveVideo('selling')}>
                                        <ProductMockup />
                                    </VideoThumbnail>
                                </Image>
                            </Panel>
                            <Panel>
                                <Description>
                                    <div $appear>
                                        <Display as="h3" tablet="none">
                                            {t('learn.marketplace.usage.buying.title.sm')}
                                        </Display>
                                        <Display as="h3" mobile="none" tablet="block">
                                            {t('learn.marketplace.usage.buying.title.xl')}
                                        </Display>
                                    </div>
                                    <DangerousHtml tag="p" $appear>
                                        {t('learn.marketplace.usage.buying.description')}
                                    </DangerousHtml>
                                </Description>
                                <Image>
                                    <VideoThumbnail onClick={() => setActiveVideo('buying')}>
                                        <MarketplaceMockup />
                                    </VideoThumbnail>
                                </Image>
                            </Panel>
                            <EndRow />
                        </Panels>
                    </Container>
                </Topic.Body>
            </Topic>
        </div>
    )
}

const Using = styled(UnstyledUsing)`
    background: #fafafa;
    border: 1px solid #fafafa;
`

export default Using
